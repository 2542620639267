/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";

const SignupCodeEditComponent = ({
                                houses,
                                house_id,
                                     appt_number,
                               onChangeHouse,
                                     onChangeAppt,
                               onClickSave, onClickCancel
                           }) => {

    return (
        <div className="w-full">

            <div className="flex flex-col mb-4">
                <label className="uppercase font-bold text-lg text-grey-darkest">Байр</label>
                <select name="house_id" value={house_id} onChange={onChangeHouse} className="border py-2 px-3 text-grey-darkest">
                    <option key={1} value=""></option>
                    {houses.map(house => {
                        return (
                            <option key={house.id} value={house.id}>{house.number} - {house.name}</option>
                        )
                    })
                    }
                </select>
            </div>
            <div className="mb-4 w-full">
                <input name="appt_number" value={appt_number} className="border py-2 px-3 w-full text-grey-darkest"
                          onChange={onChangeAppt} placeholder="Тоот оруулах"/>
            </div>

            <div className="flex justify-start space-x-2 mb-4 w-full">
                <button onClick={onClickSave}
                        className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg  py-2 px-4">
                    Үүсгэх
                </button>
                <button onClick={onClickCancel}
                        className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg py-2 px-4">
                    Буцах
                </button>
            </div>
        </div>
    )
}

export default SignupCodeEditComponent;