/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useReducer} from "react";
import {createPaymentNote, createPost, createSignupCode, fetchHouses, fetchOrder} from "./actions/actions";
import {ActionTypes} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaAngleDown} from 'react-icons/fa';
import history from "./history";
import bank from "./images/payment-account.png";
import nano from "./images/nano-receive.png"
import classNames from "classnames";
import PostEditComponent from "./components/PostEditComponent";
import SignupCodeEditComponent from "./components/SignupCodeEditComponent";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    appt_number:"",
    house_id:null,
    code:{},
    houses:[],
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_SIGNUP_CODE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_SIGNUP_CODE_SUCCESS: {
            return {
                ...state,
                code: action.code,
                loading: false
            }
        }
        case ActionTypes.GET_HOUSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_HOUSES_SUCCESS: {
            return {
                ...state,
                houses: action.houses,
                loading: false
            }
        }
        case 'CHANGE_APPT': {
            return {
                ...state,
                appt_number: action.appt_number,
            }
        }
        case 'CHANGE_HOUSE': {
            return {
                ...state,
                house_id: action.house_id,
            }
        }
        default:
            return state;
    }
};


const changeAppt = (appt_number) => {
    return {
        type: 'CHANGE_APPT',
        appt_number
    }
}
const changeHouse = (house_id) => {
    return {
        type: 'CHANGE_HOUSE',
        house_id
    }
}


const SignupCodeCreate = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { appt_number, house_id,     houses} = state;

    useEffect(() => {
        fetchHouses(dispatch)
    }, []);

    const handleChangeAppt = (e) => {
        dispatch(changeAppt(e.target.value));
    };
    const handleChangeHouse = (e) => {
        dispatch(changeHouse(e.target.value));
    };

    const handleSave = (e) => {
        if (appt_number === "") {
            toast.error("Тоот хоосон байж болохгүй!");
            return;
        }
        if (!house_id) {
            toast.error("Байр хоосон байж болохгүй!");
            return;
        }
        let info = {}
        info.appt_number = appt_number;
        info.house_id = house_id;
        createSignupCode(dispatch, info).then(response => {
                toast.success("Код үүсгэлээ!");
            }
        ).catch(error => {
            toast.error("Код үүсгэхэд алдаа гарлаа. Хуучин үүссэн код байна!");

        });
    };
    const handleCancel = (e) => {
        e.preventDefault();
        history.goBack()
    }

    return (
        <div className="p-5">

            <h2 className="mt-1 text-lg font-bold">Код үүсгэх</h2>
            <SignupCodeEditComponent
                onChangeAppt={handleChangeAppt}
                onChangeHouse={handleChangeHouse}
                onClickCancel={handleCancel} onClickSave={handleSave}
                houses = {houses}
                house_id={house_id} appt_number={appt_number}/>
        </div>
    )
}


export default SignupCodeCreate;

