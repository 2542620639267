/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useReducer} from "react";
import {createPaymentNote, createPost, fetchOrder, fetchPost, fetchPosts, updatePost} from "./actions/actions";
import {ActionTypes} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaAngleDown} from 'react-icons/fa';
import history from "./history";
import bank from "./images/payment-account.png";
import nano from "./images/nano-receive.png"
import classNames from "classnames";
import PostEditComponent from "./components/PostEditComponent";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    post:{},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_POST_SUCCESS: {
            return {
                ...state,
                post: action.post,
                loading: false
            }
        }
        case ActionTypes.UPDATE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.UPDATE_POST_SUCCESS: {
            return {
                ...state,
                post: action.post,
                loading: false
            }
        }
        case 'CHANGE_NOTE': {
            let p = {...state.post};
            p.subject = action.subject;
            return {
                ...state,
                post: p,
            }
        }
        default:
            return state;
    }
};



const changeNote = (subject) => {
    return {
        type: 'CHANGE_NOTE',
        subject
    }
}

const PostEdit = ({match: {params: {id}}}) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { post} = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchPost(dispatch, id)
    }, [id]);

    const handleChangeField = (e) => {
        dispatch(changeNote(e.target.value));
    };

    const handleSave = (e) => {
        if (post.subject === "") {
            toast.error("Мэдэгдэл хоосон байж болохгүй!");
            return;
        }
        let info = {}
        info.subject = post.subject;
        updatePost(dispatch, id, info).then(response => {
                toast.success("Мэдэгдэл хадгаллаа!");
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = (e) => {
        e.preventDefault();
        history.goBack()
    }

    return (
        <div className="p-5" >

            <h2 className="mt-1 text-lg font-bold">Мэдэгдэл өөрчлөх</h2>
            <PostEditComponent onChangeField={handleChangeField} onClickCancel={handleCancel} onClickSave={handleSave} subject={post.subject}/>
        </div>
    )
}


export default PostEdit;

