/**
 * Created by gantushig on 11/6/15.
 */

import {useHistory} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import ReactPaginate from "react-paginate";
import {createCode, fetchHouses, fetchReadings, removeReading} from "./actions/actions";
import {ActionTypes, Page} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaTimes} from 'react-icons/fa';
import dayjs from "dayjs";
import qs from "query-string";
import history from "./history";
import classd from "classd";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    openModal:false,
    idToRemove:null,
    search_form: {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        house: "",
        appt: ""},
    readings: [],
    houses:[],
    meta: {totalPages: 0},
    loading: false,
    refresh: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_READINGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_READINGS_SUCCESS: {
            return {
                ...state,
                readings: action.readings,
                meta: action.meta,
                loading: false
            }
        }
        case ActionTypes.DELETE_READING_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.DELETE_READING_SUCCESS: {
            let a = state.readings.filter( p=> {
                if (p.id !== action.reading.id) {
                    return p;
                }
            })
            return {
                ...state,
                readings: a,
                loading: false
            }
        }
        case ActionTypes.GET_HOUSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_HOUSES_SUCCESS: {
            return {
                ...state,
                houses: action.houses,
                loading: false
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.search_form};
            c[action.name] = action.value
            // if (action.name === "width") {
            //     c["height"] = action.value;
            // }
            return {
                ...state,
                search_form: c,
            }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                openModal: true,
                idToRemove:action.id
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                openModal: false,
                idToRemove:null
            }
        }

        default:
            return state;
    }
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const openModalDialog = (id) => {
    return {
        type: 'OPEN_MODAL',
        id
    }
}
const closeModalDialog = () => {
    return {
        type: 'CLOSE_MODAL',
    }
}

const ReadingsPage = ({
                          location: {search}
                      }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {readings, meta, loading, houses, search_form, idToRemove, openModal} = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let year = parsed["y"];
    let month = parsed["m"];
    let house = parsed["h"];
    let appt = parsed["a"];
    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (year === undefined) {
        year = null;
    }
    if (month === undefined) {
        month = null;
    }
    if (house === undefined) {
        house = null;
    }
    if (appt === undefined) {
        appt = null;
    }

    useEffect(() => {
        console.log('useEffect called');
        fetchReadings(dispatch, year, month, house, appt, page, pagesize, orderField, isAsc)
    }, [year, month, house, appt, orderField, isAsc, page, pagesize]);

    useEffect(() => {
        fetchHouses(dispatch)
    }, []);

    //const history = useHistory();

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleRemoveReading = (e, id) => {
        dispatch(openModalDialog(id));
    };
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["h"] = search_form.house;
        parsed["a"] = search_form.appt;
        parsed["y"] = search_form.year;
        parsed["m"] = search_form.month;
        history.push("?" + qs.stringify(parsed))
    };

    const getItemClear = (items) => {
        let ret = "";
        items.forEach((v, i) => {
            let name = ""
            if (i === 0) {
                name = "Ванн "
            } else  if ( i === 1) {
                name = "Гал тогоо "
            } else {
                name = "Байршил " + (i + 1)
            }
            let a = name + ": Халуун ус " + v.h + ", Хүйтэн ус " + v.c + ";";
            ret += a;
        });
        return ret;
    }

    const handleCloseModal = (e) => {
        dispatch(closeModalDialog());
    }
    const handleOkModal = (e) => {
        dispatch(closeModalDialog());
        removeReading(dispatch, idToRemove).then(response => {
                //fetchReadings(dispatch);
                toast.success("Амжилттай устгагдлаа!");
            }
        ).catch(error => {
            alert(error)
        });

    }

    const getHouseName = (item) => {
        let ret = "Байр:";
        if (item.house_id) {
            ret += item.house_number2;
            if (item.house_name) {
                ret += " ( " + item.house_name + ")";
            }
        } else if (item.house_number) {
            ret += item.house_number;
        }
        if (item.appt_id) {
            ret += " Тоот:" + item.appt_number2;
        } else if (item.appt_number) {
            ret += " Тоот:" + item.appt_number;
        }

        //{item.house_name} - {item.appt_number}
        return ret;
    }
    const items = readings.map(
        (item) => {
            return (
                <tr key={item.id} className={classd` ${{'bg-white': item.appt_id === null}} ${{'bg-green-200': item.appt_id !== null}}`}>
                    <td className="px-8 py-4 border ">
                        {getHouseName(item)}
                    </td>
                    <td className="px-8 py-4 border flex flex-col">
                        <span className="text-sm">{getItemClear(item.readings)}</span>
                        <span className="block my-2 text-sm">{item.created && dayjs(item.created).format("MM/DD/YYYY")}</span>
                    </td>
                    <td className="px-2 border">
                            <button className="bg-blue-500 p-1 text-white rounded-full"
                                    onClick={(e) => handleRemoveReading(e, item.id)}><FaTimes size={16}/></button>
                    </td>
                </tr>
            )
        });
    return (
        <div className="w-full p-5">

            <h2 className="mt-1 text-gl font-medium">Усны заалтууд</h2>

            <SearchPanel houses={houses}
                year={search_form.year} onChangeField={handleChangeField}
                month={search_form.month}
                house={search_form.house}
                appt={search_form.appt}
                onClickSearch={handleSearch}

            />
            <table className="shadow-lg bg-white border-collapse border table-fixed w-full">
                <thead>
                <tr className="bg-gray-200">
                    <th className="w-3/12 text-left px-8 py-4 border">Байр/Тоот</th>
                    <th className="text-left px-8 py-4 border">Заалт</th>
                    <th className="w-1/12  text-left px-8 py-4 border"></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}

                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}

                />
            </nav>


            <div
                className={classd`h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ${{'hidden': !openModal}}`}
            >
                <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
                    <div className="border-b px-4 py-2 flex justify-between items-center">
                        <h3 className="font-semibold text-lg">Заалт устгах</h3>
                        <button className="text-black close-modal" onClick={handleCloseModal}><FaTimes/></button>
                    </div>
                    <div className="p-3 text-sm">
                        Та заалт устгахдаа итгэлтэй байна уу?
                    </div>
                    <div className="flex justify-end items-center w-100 border-t p-3">
                        <button onClick={handleCloseModal}
                                className="bg-gray-600 hover:bg-gray-700 px-3 py-1 rounded text-white mr-1 close-modal">Болих
                        </button>
                        <button onClick={handleOkModal}
                                className="bg-green-600 hover:bg-green-700 px-3 py-1 rounded text-white">Тийм
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )

}


const SearchPanel = ({
                         houses, year, month,
                         house, appt,
                         onChangeField,
                         onClickSearch
                     }) => {

    return (
        <div className="my-3 mx-0">
                <div className="flex flex-col mb-4 hidden">
                    <label className="uppercase font-bold text-lg text-grey-darkest">Байр</label>
                    <select name="house" value={house} onChange={onChangeField} className="border py-2 px-3 text-grey-darkest">
                        <option key={1} value=""></option>
                        {houses.map(house => {
                                return (
                                    <option key={house.id} value={house.id}>{house.number} - {house.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            <div className="flex flex-col mb-4">
                <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">Байр</label>
                <input name="house" type="text" value={house}  className="border py-2 px-3 text-grey-darkest"
                       onChange={onChangeField} placeholder="Байрны дугаар оруулах"/>
            </div>

                <div className="flex flex-col mb-4">
                    <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">Тоот</label>
                    <input name="appt" type="text" value={appt}  className="border py-2 px-3 text-grey-darkest"
                           onChange={onChangeField} placeholder="Тоотын дугаар оруулах"/>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">Он</label>
                    <input name="year" type="text" value={year}  className="border py-2 px-3 text-grey-darkest"
                           onChange={onChangeField} placeholder="Он оруулах"/>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">Сар</label>
                    <input name="month" type="text" value={month} className="border py-2 px-3 text-grey-darkest"
                           onChange={onChangeField} placeholder="Сар оруулах"/>
                </div>


                <button onClick={onClickSearch} className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg mx-auto px-4 py-2 rounded">
                    Хайх
                </button>
        </div>
    )
}

export default ReadingsPage;

