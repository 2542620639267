/**
 * Created by gantushig on 11/6/15.
 */

import {useHistory} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import ReactPaginate from "react-paginate";
import {
    createCode,
    fetchHouses,
    fetchReadings,
    fetchValuesFree,
    getUserInfo,
    removeReading,
    savePost
} from "./actions/actions";
import {ActionTypes, Page} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaTimes} from 'react-icons/fa';
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import qs from "query-string";
import history from "./history";
import classd from "classd";

dayjs.extend(dayjsPluginUTC)

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    search_form: {
        from: null,
        to: null,
    },
    readings: [],
    post: null,
    code: null,
    name: null,
    meta: {totalPages: 0},
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_VALUES_FREE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_VALUES_FREE_SUCCESS: {
            return {
                ...state,
                readings: action.readings,
                meta: action.meta,
                loading: false
            }
        }
        case ActionTypes.GET_USERINFO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_USERINFO_SUCCESS: {
            return {
                ...state,
                code: action.code,
                name: action.name,
                post: action.post,

                loading: false
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.search_form};
            let p = {...state.post};
            if (action.name === "post") {
                p = action.value;
            } else {
                c[action.name] = action.value;
            }


            return {
                ...state,
                post: p,
                search_form: c,
            }
        }

        default:
            return state;
    }
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const ValuesFreePage = ({
                            location: {search}
                        }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {readings, post, meta, loading, search_form} = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let from = parsed["f"];
    let to = parsed["t"];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (from === undefined) {
        from = null;
    }
    if (to === undefined) {
        to = null;
    }

    useEffect(() => {
        console.log('useEffect called');
        getUserInfo(dispatch)
    }, []);

    useEffect(() => {
        console.log('useEffect called');
        fetchValuesFree(dispatch, from, to, page, pagesize, orderField, isAsc)
    }, [from, to, orderField, isAsc, page, pagesize]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["f"] = search_form.from;
        parsed["t"] = search_form.to;
        history.push("?" + qs.stringify(parsed))
    };

    const handleClickSave = (e) => {
        savePost(dispatch, post)
    };

    const items = readings.map(
        (item) => {
            return (
                <tr key={item.id}>
                    <td className="px-8 py-4 border ">
                        {item.house}
                    </td>
                    <td className="px-8 py-4 border ">
                        {item.appt}
                    </td>
                    <td className="px-8 py-4 border ">
                        {item.readings}
                    </td>
                    <td className="px-8 py-4 border ">
                        {dayjs(item.created).format("YYYY-MM-DD  HH:mm")}
                    </td>
                </tr>
            )
        });
    return (
        <div className="w-full p-5">

            <h2 className="mt-1 text-gl font-medium">Мэдэгдэл</h2>
            <textarea name="post" className="border p-1 w-full" value={post} onChange={handleChangeField}/>
            <button onClick={handleClickSave}
                    className="float-right block bg-green-500 hover:bg-green-800 text-white uppercase text-lg mx-auto px-4 py-2 rounded">
                Хадгалах
            </button>
            <h2 className="mt-1 text-gl font-medium">Усны заалтууд</h2>

            <SearchPanel
                from={search_form.from} onChangeField={handleChangeField}
                to={search_form.to}
                onClickSearch={handleSearch}
            />

            <br/>
            <table className="table-auto w-full">
                <thead>
                <tr className="">
                    <th className="w-1/12 text-left px-8 py-4 border">Байр</th>
                    <th className="w-1/12 text-left px-8 py-4 border">Тоот</th>
                    <th className="text-left px-8 py-4 border">Заалт</th>
                    <th className="text-left w-3/12 px-8 py-4 border">Ирсэн</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                />
            </nav>

        </div>
    )

}


const SearchPanel = ({
                         from, to,
                         onChangeField,
                         onClickSearch
                     }) => {

    return (
        <div className="mt-5">
            <div className="flex flex-col mb-4">
                <label className="mb-2 uppercase font-normal text-base text-grey-darkest">Эхлэх огноо
                    (YYYY/MM/DD)</label>
                <input name="from" type="text" value={from} className="border py-2 px-3 text-grey-darkest"
                       onChange={onChangeField} placeholder="Эхлэх огноо оруулах"/>
            </div>

            <div className="flex flex-col mb-4">
                <label className="mb-2 uppercase font-normal text-base text-grey-darkest">Дуусах огноо
                    (YYYY/MM/DD)</label>
                <input name="to" type="text" value={to} className="border py-2 px-3 text-grey-darkest"
                       onChange={onChangeField} placeholder="Дуусах огноо оруулах"/>
            </div>

            <button onClick={onClickSearch}
                    className="float-right mb-2 bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg p-2 rounded">
                Хайх
            </button>
        </div>
    )
}

export default ValuesFreePage;

