/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";

const PostEditComponent = ({
                               subject,
                               onChangeField,
                               onClickSave, onClickCancel
                           }) => {

    return (
        <div className="w-full">

            <div className="mb-4 w-full">
                <textarea name="subject" value={subject} className="border py-2 px-3 w-full text-grey-darkest"
                          onChange={onChangeField} placeholder="Мэдэгдэл оруулах"/>
            </div>

            <div className="flex justify-start space-x-2 mb-4 w-full">
                <button onClick={onClickSave}
                        className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg  py-2 px-4">
                    Хадгалах
                </button>
                <button onClick={onClickCancel}
                        className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg py-2 px-4">
                    Буцах
                </button>
            </div>
        </div>
    )
}

export default PostEditComponent;