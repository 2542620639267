/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import {Link} from "react-router-dom";
import {createUser, signup} from "./actions/actions";
import {ActionTypes} from "./Constants";
import history from "./history";
import packageJson from "../package.json";
import {FaBuilding} from "react-icons/fa";
import {css} from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 20px 0;
  border-color: #177892;
`;


const initialState = {
    org_id: 0,
    name: "",
    email: "",
    password: "",
    loading: false,
    errorMessage: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_USER_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case 'CHANGE_EMAIL': {
            return {
                ...state,
                email: action.email
            }
        }
        case 'CHANGE_NAME': {
            return {
                ...state,
                name: action.name
            }
        }
        case 'CHANGE_ORG': {
            return {
                ...state,
                org_id: action.org_id
            }
        }
        case 'CHANGE_PASSWORD': {
            return {
                ...state,
                password: action.password
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        }

        default:
            return state;
    }
};


const changeEmail = (email) => {
    return {
        type: 'CHANGE_EMAIL',
        email
    }
}
const changeName = (name) => {
    return {
        type: 'CHANGE_NAME',
        name
    }
}
const changeOrg = (org_id) => {
    return {
        type: 'CHANGE_ORG',
        org_id
    }
}
const changePassword = (password) => {
    return {
        type: 'CHANGE_PASSWORD',
        password
    }
}


const setError = (errorMessage) => {
    return {
        type: 'SET_ERROR',
        errorMessage
    }
}

const isEmail = (val) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
        return false;
    } else {
        return true;
    }
}


const validateName = (value) => {
    if (value.length < 3 || value.length > 20) {
        return false;
    } else {
        return true;
    }
}

const validatePassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return false;
    } else {
        return true;
    }
}

const AdminUser = () => {
    //const [email, setEmail] = useState("");

    const [state, dispatch] = useReducer(reducer, initialState);
    const {name, email, password, org_id, loading, errorMessage} = state;

    //setError(sauth.errorMEssage)
    const handleChangeEmail = (e) => {
        dispatch(changeEmail(e.target.value));
    };
    const handleChangeName = (e) => {
        dispatch(changeName(e.target.value));
    };
    const handleChangePassword = (e) => {
        dispatch(changePassword(e.target.value));
    };
    const handleChangeOrg = (e) => {
        dispatch(changeOrg(e.target.value));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('send');
        if (!!name && !!email && !!password) {

        } else {
            dispatch(setError("Хэрэглэгчийн нэр, имэйл, нууц үг оруулна уу!"));
            return;
        }
        if (!validateName(name)) {
            dispatch(setError("Хэрэглэгчийн нэр 3 аас 20 үсэгний урттай байна!"));
            return;
        }
        if (!isEmail(email)) {
            dispatch(setError("Үнэн зөв емайл хаяг оруулна уу!"));
            return;
        }
        if (!validatePassword(password)) {
            dispatch(setError("Нууц үг 6 аас 40 үсэгний урттай байна!"));
            return;
        }

        let info = {}
        info.name = name;
        info.email = email;
        info.password = password;
        info.org_id = org_id;
        createUser(dispatch, info).then(response => {

            }
        ).catch(error => {
            alert(error)
        });

    };


    return (
        <div>
            <nav className="flex items-center justify-between flex-wrap bg-white p-6">
                <div className="flex items-center flex-shrink-0  mr-6">
                    <Link to="/">
                    <span className="text-xl font-extrabold text-purple-500"><FaBuilding size={30} className="inline text-purple-500"/> MANAI BAIR</span>
                    </Link>
                </div>
                <div className="w-full block flex-grow flex items-center w-auto">
                    <div className="text-sm flex-grow">

                    </div>
                </div>
            </nav>

            <div className="group m-8 md:m-32" style={{marginBottom: "50px", marginTop: "100px"}}>

                <BarLoader color="red" loading={loading} css={override}/>
                <h1 className="mt-8  text-xl font-light">User</h1>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="group">


                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">Хэрэглэгчийн нэр</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="name"
                                type="text"
                                value={name}
                                onChange={handleChangeName}
                                placeholder="Нэр оруулах"/>
                        </div>

                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="email">Имэйл</label>
                            <input
                                className="form-input border-2 border-gray-200  px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="email"
                                type="email"
                                value={email}
                                onChange={handleChangeEmail}
                                placeholder="Email оруулах"/>
                        </div>

                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="password">Нууц уг</label>
                            <input
                                className="form-input border-2 border-gray-200  px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="password"
                                type="password"
                                value={password}
                                onChange={handleChangePassword}
                                placeholder="Нууц үг оруулах"/>
                        </div>
                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">Org id</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="org_id"
                                type="number"
                                value={org_id}
                                onChange={handleChangeOrg}
                                placeholder="Org Id оруулах"/>
                        </div>

                        <button className="w-full md:w-1/2 mt-8 py-3 px-3 bg-purple-600 font-semibold text-white" type="submit">
                            Бүртгүүлэх
                        </button>
                        {errorMessage &&
                        <div style={{margin: "5px"}}><span
                            style={{color: "red", fontSize: "smaller"}}>{errorMessage}</span></div>
                        }

                    </div>
                </form>
            </div>


            <footer>
                <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 mt-48">
                    <p>&copy;2021 Manai bair. Strong Neighbours,
                        Version {packageJson.version}</p>
                </nav>
            </footer>
        </div>
    )
}


export default AdminUser;