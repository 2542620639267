/**
 * Created by gantushig on 4/16/17.
 */

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FaBars, FaRocket, FaFortAwesomeAlt, FaBuilding} from 'react-icons/fa'
import {loginError, loginUser} from "./actions/actions";
import {Link, Redirect} from "react-router-dom";
import packageJson from "../package.json";


const isEmail = (val) => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
        return false;
    } else {
        return true;
    }
}

const LoginEmail = () => {
    const [hidden, setHidden] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const dispatch = useDispatch();
    const sauth = useSelector(state => state.auth);

    //setError(sauth.errorMEssage)
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('send');
        if ( !!email && !!password) {
            if (!isEmail(email)) {
                dispatch(loginError("Үнэн зөв емайл хаяг оруулна уу!"));
            } else {
                dispatch(loginUser(email, password));
            }
        } else {
            dispatch(loginError("Хэрэглэгчийн имэйл, нууц үг оруулна уу!"));
        }


    };

    const toggleShow = (e) => {
        e.preventDefault();
        setHidden(!hidden);
    }
    if (sauth.isAuthenticated) {
        return (
            <Redirect to="/"/>
        )
    } else {

        return (
            <div>
                <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6">
                    <div className="flex items-center flex-shrink-0 mr-6">
                        <span className="text-xl font-extrabold text-indigo-500"> <FaBuilding className="inline" size={24} /> МАНАЙ БАЙР - КОНТОР</span>
                    </div>
                    <div className="w-full block flex-grow flex items-center w-auto">
                        <div className="text-sm flex-grow">

                        </div>
                        <div>
                            <Link to="/signup"
                                  className="text-base px-4 py-2 text-indigo-500">Бүртгүүлэх</Link>
                        </div>
                    </div>
                </nav>
                <div className="mx-8 md:mx-32">
                    <h1 className="mt-8  text-xl font-light">Login</h1>
                    <form onSubmit={(e)=>handleSubmit(e)}>

                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="email">Имэйл</label>
                            <input
                                className="form-input border-2 border-gray-200  px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="email"
                                type="email"
                                value={email}
                                onChange={handleChangeEmail}
                                placeholder="Email оруулах"/>
                        </div>

                        <div className="group mt-8 ">
                            <label className="text-sm text-gray-400" htmlFor="password">Нууц уг</label>
                            <div className="flex">
                                <input
                                    className="form-input flex-grow px-2 py-2 border-t-2  border-l-2  border-b-2 border-gray-200  w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    name="password"
                                    type={hidden ? "password" : "text"}
                                    value={password}
                                    onChange={handleChangePassword}
                                    placeholder="Нууц үг оруулах"/>

                                <button
                                    className="border-t-2 border-r-2 border-b-2 w-12 flex justify-center items-center "
                                    onClick={toggleShow}>
                                    {hidden &&
                                    <svg className="bi bi-eye-slash-fill" width="1em" height="1em"
                                         viewBox="0 0 16 16" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 01-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 002.79-.588zM5.21 3.088A7.028 7.028 0 018 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 00-4.474-4.474L5.21 3.089z"/>
                                        <path
                                            d="M5.525 7.646a2.5 2.5 0 002.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 012.829 2.829z"/>
                                        <path fillRule="evenodd"
                                              d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    }
                                    {!hidden &&
                                    <svg className="bi bi-eye-fill" width="1em" height="1em"
                                         viewBox="0 0 16 16"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"/>
                                        <path fillRule="evenodd"
                                              d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    }

                                </button>
                            </div>
                        </div>
                        <button className="w-full md:w-1/2 mt-8 py-3 px-3 bg-indigo-600 font-semibold text-white"
                                onClick={handleSubmit} type="submit">
                            Нэвтрэх
                        </button>
                    </form>
                    <div style={{margin: "5px"}}><span
                        style={{color: "red", fontSize: "smaller"}}>{sauth.errorMessage}</span></div>
                    <Link to="/reset-password">Нууц үгээ мартсан?</Link>


                </div>

                <footer>
                    <nav className="flex items-center justify-between flex-wrap bg-white-500 p-6 mt-48">
                        <p>&copy; 2021-2023 Manaibair.com. Strong Neighbours,                     Version {packageJson.version}</p>
                    </nav>
                </footer>
            </div>
        )
    }
}


export default LoginEmail;