/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import {css} from "@emotion/react";
import {ActionTypes} from "./Constants";
import {resetPassword} from "./actions/actions";
import {toast} from "react-toastify";
import history from "./history";
import {FaBuilding} from "react-icons/fa";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    email: "",
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }

        case 'CHANGE_EMAIL': {
            return {
                ...state,
                email: action.email,
            }
        }
        default:
            return state;
    }
};


const changeEmail = (email) => {
    return {
        type: 'CHANGE_EMAIL',
        email
    }
}

const ResetPassword = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {email, loading} = state;

    const handleChangeEmail = (e) => {
        dispatch(changeEmail(e.target.value));
    };

    const handleSend = () => {
        console.log('send');
        let info = {}
        info.email = email;
        resetPassword(dispatch, info).then(response => {
                toast.success("Таны хүсэлт амжилттай илгээгдлээ!");
                history.push("/login")
            }
        ).catch(error => {
            alert(error)
        });
    };

    return (
        <div>
            <nav className="flex items-center justify-between flex-wrap bg-white p-6">
                <div className="flex items-center flex-shrink-0  mr-6">
                    <span className="text-xl font-extrabold text-indigo-500"> <FaBuilding size={30}
                                                                                        className="inline text-indigo-500"/> МАНАЙ БАЙР - КОНТОР</span>
                </div>
                <div className="w-full block flex-grow flex items-center w-auto">
                    <div className="text-sm flex-grow">

                    </div>
                </div>
            </nav>
            <div className="flex justify-center items-center mt-5">
                <div className="w-2/3 mx-3 md:w-1/2  lg:w-1/3 mx-5">
                    <h2>Нууц үгээ дахин тохируулах</h2>

                    <div className="mt-3">
                        <input className="border py-2 px-4 w-full" type="text" value={email}
                               onChange={(e) => handleChangeEmail(e)} placeholder="Email"/>
                    </div>

                    <button className="bg-blue-500 py-3 my-3 px-8 w-full text-white font-semibold" type="primary"
                            onClick={() => handleSend()}>
                        Reset
                    </button>
                </div>
            </div>
        </div>
    )
}


export default ResetPassword;