/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect} from "react";
import history from './history';


const MainView = () => {
    useEffect(() => {
        history.push("/values-free")
    }, []);

    return (
        <div>
        </div>
    )

}


export default MainView




