/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {loginUser, resetPassword, storePassword} from "./actions/actions";
import {Redirect} from "react-router-dom";
import {css} from "@emotion/react";
import {ActionTypes} from "./Constants";
import {toast} from "react-toastify";
import history from "./history";
import {FaRocket} from "react-icons/fa";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    password: "",
    confirmPassword:"",
    confirmed: false,
    showPassword:false,
    showConfirmPassword:false,
    errorMessage:null,
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.STORE_PASSWORD_REQUEST:
            return {
                ...state,
                confirmed: false,
                loading: true
            }
        case ActionTypes.STORE_PASSWORD_SUCCESS: {
            return {
                ...state,
                confirmed: true,
                loading: false
            }
        }

        case 'CHANGE_PASSWORD': {
            return {
                ...state,
                password: action.password,
            }
        }
        case 'CHANGE_CONFIRM_PASSWORD': {
            return {
                ...state,
                confirmPassword: action.password,
            }
        }
        case 'TOGGLE_SHOW_PASSWORD': {
            return {
                ...state,
                showPassword: !state.showPassword,
            }
        }
        case 'TOGGLE_SHOW_CONFIRM_PASSWORD': {
            return {
                ...state,
                showConfirmPassword: !state.showConfirmPassword,
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        }
        default:
            return state;
    }
};


const changePassword = (password) => {
    return {
        type: 'CHANGE_PASSWORD',
        password
    }
}

const changeConfirmPassword = (password) => {
    return {
        type: 'CHANGE_CONFIRM_PASSWORD',
        password
    }
}



const toggleShowPassword = () => {
    return {
        type: 'TOGGLE_SHOW_PASSWORD'
    }
}

const toggleShowConfirmPassword = () => {
    return {
        type: 'TOGGLE_SHOW_CONFIRM_PASSWORD'
    }
}


const setError = (errorMessage) => {
    return {
        type: 'SET_ERROR',
        errorMessage
    }
}

const validatePassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return false;
    } else {
        return true;
    }
}

const StorePassword = ({match: {params: {code}}}) => {


    const [state, dispatch] = useReducer(reducer, initialState);
    const {password,confirmPassword,  loading, confirmed, errorMessage} = state;

    const handleChangePassword = (e) => {
        dispatch(changePassword(e.target.value))
    };
    const handleChangeConfirmPassword = (e) => {
        dispatch(changeConfirmPassword(e.target.value))
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO check
        if (!validatePassword(password)) {
            dispatch(setError("Нууц үг 6 аас 40 үсэгний урттай байна!"));
            return;
        }
        if (!validatePassword(confirmPassword)) {
            dispatch(setError("Нууц Үгээ давтан 6 аас 40 үсэгний урттай байна!"));
            return;
        }
        if (password !== confirmPassword) {
            dispatch(setError("The password confirmation does not match."));
            return;
        }

        console.log('send');
        let info = {}
        info.code = code;
        info.password = password;
        storePassword(dispatch, info);
        //toast.success("Таны хүсэлт амжилттай илгээгдлээ!");
        history.push("/login");

        // .then(response => {
        //         toast.success("Таны хүсэлт амжилттай илгээгдлээ!");
        //         history.push("/login")
        //     }
        // ).catch(error => {
        //     alert(error)
        // });

    };

    const handleToggleShowPassword = () => {
        dispatch(toggleShowPassword());
    }
    const handleToggleShowConfirmPassword = () => {
        dispatch(toggleShowConfirmPassword());
    }

        return (
            <div>
                <nav className="flex items-center justify-between flex-wrap bg-white p-6">
                    <div className="flex items-center flex-shrink-0  mr-6">
                        <span className="text-xl font-extrabold text-purple-500">QRCODE</span> <FaRocket size={30} className="inline text-purple-500"/>
                    </div>
                    <div className="w-full block flex-grow flex items-center w-auto">
                        <div className="text-sm flex-grow">

                        </div>
                    </div>
                </nav>
                <div className="flex justify-center items-center mt-5">
                    {!confirmed &&
                    <form onSubmit={(e) => handleSubmit(e)}
                          className="w-2/3 mx-3 md:w-1/2  lg:w-1/3 mx-5">

                            <h2>Нууц Үгээ Өөрчлөх</h2>


                            <div className="mt-3">
                                <input className="border py-2 px-4 w-full" type="password" value={password}
                                       onChange={(e) => handleChangePassword(e)} placeholder="Нууц Үг"/>
                            </div>
                            <div className="mt-3">
                                <input className="border py-2 px-4 w-full" type="password" value={confirmPassword}
                                       onChange={(e) => handleChangeConfirmPassword(e)}
                                       placeholder="Нууц Үгээ давтан оруулах"/>
                            </div>

                            <button className="bg-blue-500 py-3 my-3 px-8 w-full text-white font-semibold" type="primary">
                                Reset
                            </button>
                            {errorMessage &&
                            <div style={{margin: "5px"}}><span
                                style={{color: "red", fontSize: "smaller"}}>{errorMessage}</span></div>
                            }

                    </form>
                    }
                </div>
            </div>
        )
}


export default StorePassword;