/**
 * Created by gantushig on 4/18/19.
 */

import {ActionTypes, Endpoints, LOGGED_EMAIL, LOGGED_IN} from '../Constants';
import util from 'util'
//import axios from 'axios'
import axios from '../axiosConfig'
import qs from "query-string";

const FileDownload = require('js-file-download');


function requestLogin(creds) {
    return {
        type: ActionTypes.LOGIN_REQUEST
    }
}

function receiveLogin() {
    return {
        type: ActionTypes.LOGIN_SUCCESS
    }
}

export function loginError(message) {
    return {
        type: ActionTypes.LOGIN_FAILURE,
        message
    }
}

export function loginUser( email, password) {
    return dispatch => {
        const creds = {
            email: email,
            password: password
        };

        // We dispatch requestLogin to kickoff the call to the API
        dispatch(requestLogin(creds))
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.LOGIN_URL}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(creds);

        return axios(config)
            .then(response => {
                dispatch(receiveLogin())
                console.log(util.inspect(response, false, null));
                localStorage.setItem(LOGGED_IN, true)
                localStorage.setItem(LOGGED_EMAIL, email)

            })
            .catch(
                err => {
                    console.log("Error Login: ", err)
                    let msg = err.response.data.message
                    if (err.response.status === 403) {
                        msg = "Хэрэглэгч хүлээгдэж байна. Имэйлээ шалгаж бүртгэлээ баталгаажуулна уу!"
                    } else if (err.response.status === 401) {
                        msg = "Хэрэглэгчийн email эсвэл нууц үг буруу байна!"
                    } else if (err.response.status === 504) {
                        msg = "Gateway timeout. Network error!"
                    } else {
                        msg = "Unknown Error! " + err.response.data.message + " Status:"+ err.response.status
                    }
                    dispatch(loginError(msg))

                }
            )
    }
}


function requestSignup(creds) {
    return {
        type: ActionTypes.SIGNUP_REQUEST
    }
}

function receiveSignup() {
    return {
        type: ActionTypes.SIGNUP_SUCCESS
    }
}

function signupError(message) {
    return {
        type: ActionTypes.SIGNUP_FAILURE,
        message
    }
}

export const signup = (dispatch, name, email, password) => {

    return new Promise(function (resolve, reject) {

        const creds = {
            email: email,
            name: name,
            password: password
        };

        // We dispatch requestLogin to kickoff the call to the API
        dispatch(requestSignup(creds))
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.SIGNUP_URL}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(creds);

        axios(config)
            .then(function (response) {
                console.log(util.inspect(response, false, null));
                dispatch(receiveSignup())
                resolve(response);
            })
            .catch(
                err => {
                    reject(err);
                }
            )
    });

}

export const requestLogout = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST
    }
}

export const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS
    }
};

// Logs the user out
export const logoutUser = () => {
    let endpoint = Endpoints.LOGOUTV1_URL;
    return dispatch => {
        dispatch(requestLogout())

        return axios.get(endpoint)
            .then(response => {
                //localStorage.removeItem(LOGGED_IN)
                dispatch(logoutSuccess())
            })
            .catch(error => {
                throw(error);
            });
    }
};


export const confirmEmailRequest = () => {
    return {
        type: ActionTypes.CONFIRM_EMAIL_REQUEST
    }
};

export const confirmEmailSuccess = (status) => {
    return {
        type: ActionTypes.CONFIRM_EMAIL_SUCCESS,
        status
    }
};

export const confirmEmail = (dispatch, code) => {

    dispatch(confirmEmailRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.CONFIRM_EMAIL}/${code}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(confirmEmailSuccess(response.data.status))
        })
        .catch(error => {
            throw(error);
        });
}


export const resetPasswordRequest = () => {
    return {
        type: ActionTypes.RESET_PASSWORD_REQUEST
    }
};

export const resetPasswordSuccess = (status) => {
    return {
        type: ActionTypes.RESET_PASSWORD_SUCCESS,
        status
    }
};

export const resetPassword = (dispatch, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(resetPasswordRequest())
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.RESET_PASSWORD}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(response => {
                dispatch(resetPasswordSuccess(response.data.status))
                resolve(response);
            })
            .catch(error => {
                reject(error)
            });
    });
}


export const storePasswordRequest = () => {
    return {
        type: ActionTypes.STORE_PASSWORD_REQUEST
    }
};

export const storePasswordSuccess = (status) => {
    return {
        type: ActionTypes.STORE_PASSWORD_SUCCESS,
        status
    }
};

export const storePassword = (dispatch, info) => {

    dispatch(storePasswordRequest())
    let header = {}
    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${Endpoints.CHANGE_PASSWORD}`;
    config["method"] = "post";
    config["data"] = JSON.stringify(info);

    return axios(config)
        .then(response => {
            dispatch(storePasswordSuccess(response.data.status))
            //resolve(response);
        })
        .catch(error => {
            //reject(error)
        });

}

export const failure = (error, message) => {
    return {
        type: ActionTypes.FAILURE,
        error,
        message
    }
};


/*
 *
 *
 *

  user trial
 *
 *
 *
 */


export const checkUserTrialRequest = () => {
    return {
        type: ActionTypes.CHECK_USER_TRIAL_REQUEST
    }
};

export const checkUserTrialSuccess = (status, error, message, days) => {
    return {
        type: ActionTypes.CHECK_USER_TRIAL_SUCCESS,
        status, error, message, days
    }
};

export const checkUserTrial = (dispatch) => {

    dispatch(checkUserTrialRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;


    let url = `${Endpoints.USER_TRIAL}`
    config["url"] = url;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(checkUserTrialSuccess(response.data.status, response.data.error, response.data.message, response.data.days))
        })
        .catch(error => {
            throw(error);
        });
}


export const startTrialRequest = () => {
    return {
        type: ActionTypes.START_USER_TRIAL_REQUEST
    }
};

export const startTrialSuccess = (status, error, message, days) => {
    return {
        type: ActionTypes.START_USER_TRIAL_SUCCESS,
        status, error, message, days
    }
};

export const startTrial = (dispatch) => {
    return new Promise(function (resolve, reject) {
        dispatch(startTrialRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.USER_TRIAL}`;
        config["method"] = "post";
        config["data"] = JSON.stringify({});

        axios(config)
            .then(function (response) {
                dispatch(startTrialSuccess(response.data.status, response.data.error, response.data.message, response.data.days))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}


/*
 *
 * qr codes
 *
 */


export const fetchCodesRequest = () => {
    return {
        type: ActionTypes.LOAD_CODES_REQUEST
    }
};

export const fetchCodesSuccess = (qrcodes, meta) => {
    return {
        type: ActionTypes.LOAD_CODES_SUCCESS,
        qrcodes,
        meta
    }
};

export const fetchCodes = (dispatch, page, pagesize, order, asc) => {

    dispatch(fetchCodesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;

    let query = {};
    query.page = page;
    query.size = pagesize;
    query.order = order;
    query.asc = asc;

    let url = `${Endpoints.CODES}/?` + qs.stringify({
        page: page,
        size: pagesize,
        order: order,
        asc: asc
    }, {skipNull: true})
    config["url"] = url;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchCodesSuccess(response.data.qrcodes, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
}



/*
devices
 */

const fetchDevicesRequest = () => {
    return {
        type: ActionTypes.LOAD_DEVICES_REQUEST
    }
};

const fetchDevicesSuccess = (devices) => {
    return {
        type: ActionTypes.LOAD_DEVICES_SUCCESS,
        devices
    }
};

export const fetchDevices = (dispatch) => {

    dispatch(fetchDevicesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;

    let url = `${Endpoints.DEVICES}`
    config["url"] = url;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchDevicesSuccess(response.data.devices))
        })
        .catch(error => {
            throw(error);
        });
}



export const deleteCodeRequest = () => {
    return {
        type: ActionTypes.DELETE_CODE_REQUEST
    }
};

export const deleteCodeSuccess = (status, code) => {
    return {
        type: ActionTypes.DELETE_CODE_SUCCESS,
        status,
        code
    }
};

export const deleteCode = (dispatch, id) => {
    return new Promise(function (resolve, reject) {
        dispatch(deleteCodeRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.CODE}/${id}`;
        config["method"] = "delete";
        config["data"] = JSON.stringify({});

        axios(config)
            .then(function (response) {
                dispatch(deleteCodeSuccess(response.data.status, response.data.qrcode))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}


/*
QR Code
 */

export const getActiveCodeCountRequest = () => {
    return {
        type: ActionTypes.GET_ACTIVE_CODE_COUNT_REQUEST
    }
};
export const getActiveCodeCountSuccess = (status, count) => {
    return {
        type: ActionTypes.GET_ACTIVE_CODE_COUNT_SUCCESS,
        count
    }
};
export const getActiveCodeCount = (dispatch, codeId) => {


    dispatch(getActiveCodeCountRequest());
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.ORDERS}/active`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(getActiveCodeCountSuccess(response.data.status, response.data.total))
        })
        .catch(error => {
            throw(error);
        });

}


export const fetchCodeRequest = () => {
    return {
        type: ActionTypes.GET_CODE_REQUEST
    }
};
export const fetchCodeSuccess = (status, qrcode) => {
    return {
        type: ActionTypes.GET_CODE_SUCCESS,
        status,
        qrcode
    }
};
export const fetchCode = (dispatch, code) => {


    dispatch(fetchCodeRequest());
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.CODE}/key/${code}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchCodeSuccess(response.data.status, response.data.qrcode))
        })
        .catch(error => {
            throw(error);
        });

}


export const fetchCodeStylingRequest = () => {
    return {
        type: ActionTypes.GET_CODE_STYLING_REQUEST
    }
};
export const fetchCodeStylingSuccess = (status, options, image, filename) => {
    return {
        type: ActionTypes.GET_CODE_STYLING_SUCCESS,
        status,
        options,
        image,
        filename
    }
};

export const fetchCodeStyling = (dispatch, codeId) => {
    dispatch(fetchCodeStylingRequest());
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.CODE_STYLING}/${codeId}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchCodeStylingSuccess(response.data.status, response.data.options, response.data.image, response.data.filename))
        })
        .catch(error => {
            throw(error);
        });

}






export const enterReadingFromQrCodeRequest = () => {
    return {
        type: ActionTypes.ENTER_READING_FROM_QRCODE_REQUEST
    }
};

export const enterReadingFromQrCodeSuccess = (reading) => {
    return {
        type: ActionTypes.ENTER_READING_FROM_QRCODE_SUCCESS,
        reading
    }
};
export const enterReadingFromQrCode = (dispatch, info) => {

    return new Promise(function (resolve, reject) {


        // We dispatch requestLogin to kickoff the call to the API
        dispatch(requestSignup())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.ENTER_FROM_QRCODE}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(enterReadingFromQrCodeSuccess(response.data.reading))
                resolve(response);
            })
            .catch(
                err => {
                    reject(err);
                }
            )
    });

}


// export const fetchCodeRequest = () => {
//     return {
//         type: ActionTypes.GET_CODE_REQUEST
//     }
// };
// export const fetchCodeSuccess = (code) => {
//     return {
//         type: ActionTypes.GET_CODE_SUCCESS,
//         code
//     }
// };
// export const fetchCode = (dispatch, qrKey) => {
//
//
//     dispatch(fetchCodeRequest());
//     let header = {};
//     header["Accept"] = "application/json";
//     header["Content-Type"] = "application/json";
//
//     let config = {};
//     config["headers"] = header;
//     config["url"] = `${Endpoints.CODE}/${qrKey}`;
//     config["method"] = "get";
//
//     return axios(config)
//         .then(response => {
//             dispatch(fetchCodeSuccess(response.data.code))
//         })
//         .catch(error => {
//             throw(error);
//         });
//
// }
/*
remove
 */
export const removeOrderRequest = () => {
    return {
        type: ActionTypes.DELETE_ORDER_REQUEST
    }
};

export const removeOrderSuccess = (order) => {
    return {
        type: ActionTypes.DELETE_ORDER_SUCCESS,
        order
    }
};

export const removeOrder = (dispatch, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeOrderRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.ORDER}/${id}`;
        config["method"] = "delete";

        axios(config)
            .then(response => {
                dispatch(removeOrderSuccess(response.data.order))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};



/*
    Readings
 */

const fetchReadingsRequest = () => {
    return {
        type: ActionTypes.GET_READINGS_REQUEST
    }
};

const fetchReadingsSuccess = (readings, meta) => {
    return {
        type: ActionTypes.GET_READINGS_SUCCESS,
        readings,
        meta
    }
};

export const fetchReadings = (dispatch, year, month, house, appt, page, pagesize, order, asc) => {

    dispatch(fetchReadingsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.READINGS}/?year=${year}&month=${month}&house=${house}&appt=${appt}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchReadingsSuccess(response.data.readings, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
}


const fetchValuesFreeRequest = () => {
    return {
        type: ActionTypes.GET_VALUES_FREE_REQUEST
    }
};

const fetchValuesFreeSuccess = (readings, meta) => {
    return {
        type: ActionTypes.GET_VALUES_FREE_SUCCESS,
        readings,
        meta
    }
};

export const fetchValuesFree = (dispatch, from, to, page, pagesize, order, asc) => {

    dispatch(fetchReadingsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.VALUES_FREE}/?from=${from}&to=${to}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchValuesFreeSuccess(response.data.readings, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
}


const getUserInfoRequest = () => {
    return {
        type: ActionTypes.GET_USERINFO_REQUEST
    }
};

const getUserInfoSuccess = (code, name, post) => {
    return {
        type: ActionTypes.GET_USERINFO_SUCCESS,
        code, name, post
    }
};

export const getUserInfo = (dispatch) => {

    dispatch(getUserInfoRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.CODE}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(getUserInfoSuccess(response.data.code, response.data.name, response.data.post))
        })
        .catch(error => {
            throw(error);
        });
}

/*
remove
 */
export const removeReadingRequest = () => {
    return {
        type: ActionTypes.DELETE_READING_REQUEST
    }
};

export const removeReadingSuccess = (reading) => {
    return {
        type: ActionTypes.DELETE_READING_SUCCESS,
        reading
    }
};

export const removeReading = (dispatch, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeReadingRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.READING}/${id}`;
        config["method"] = "delete";

        axios(config)
            .then(response => {
                dispatch(removeReadingSuccess(response.data.reading))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};



/*
save
 */
 const savePostRequest = () => {
    return {
        type: ActionTypes.SAVE_POST_REQUEST
    }
};

export const savePostSuccess = () => {
    return {
        type: ActionTypes.SAVE_POST_SUCCESS,
    }
};

export const savePost = (dispatch, post) => {

    return new Promise(function (resolve, reject) {
        dispatch(savePostRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let dto = {};
        dto.post = post;
        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.CODE}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(dto);

        axios(config)
            .then(response => {
                dispatch(savePostSuccess())
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};


export const fetchPostsRequest = () => {
    return {
        type: ActionTypes.GET_POSTS_REQUEST
    }
};

export const fetchPostsSuccess = (posts, meta) => {
    return {
        type: ActionTypes.GET_POSTS_SUCCESS,
        posts,
        meta
    }
};

export const fetchPosts = (dispatch, status, page, pagesize, order, asc) => {

    dispatch(fetchPostsRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.POSTS}/?status=${status}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchPostsSuccess(response.data.posts, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
}


export const fetchPostRequest = () => {
    return {
        type: ActionTypes.GET_POST_REQUEST
    }
};
export const fetchPostSuccess = (status, post) => {
    return {
        type: ActionTypes.GET_POST_SUCCESS,
        status,
        post
    }
};

export const fetchPost = (dispatch, postId) => {
    dispatch(fetchPostRequest());
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.POST}/${postId}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchPostSuccess(response.data.status, response.data.post))
        })
        .catch(error => {
            throw(error);
        });
}


export const createPostRequest = () => {
    return {
        type: ActionTypes.CREATE_POST_REQUEST
    }
};

export const createPostSuccess = (note) => {
    return {
        type: ActionTypes.CREATE_POST_SUCCESS,
        note
    }
};

export const createPost = (dispatch, info) => {
    return new Promise(function (resolve, reject) {

        dispatch(createPostRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.POSTS}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(response => {
                dispatch(createPostSuccess(response.data.post))
                resolve(response);
            })
            .catch(error => {
                reject(error);

            });
    });
};


export const removePostRequest = () => {
    return {
        type: ActionTypes.DELETE_POST_REQUEST
    }
};

export const removePostSuccess = (post) => {
    return {
        type: ActionTypes.DELETE_POST_SUCCESS,
        post
    }
};

export const removePost = (dispatch, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removePostRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.POST}/${id}`;
        config["method"] = "delete";

        axios(config)
            .then(response => {
                dispatch(removePostSuccess(response.data.post))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};


export const publishPostRequest = () => {
    return {
        type: ActionTypes.PUBLISH_POST_REQUEST
    }
};

export const publishPostSuccess = (status, post) => {
    return {
        type: ActionTypes.PUBLISH_POST_SUCCESS,
        status,
        post
    }
};

export const publishPost = (dispatch, id) => {
    return new Promise(function (resolve, reject) {
        dispatch(publishPostRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.POST}/${id}/publish`;
        config["method"] = "post";
        config["data"] = JSON.stringify({});

        axios(config)
            .then(function (response) {
                dispatch(publishPostSuccess(response.data.status, response.data.post))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}


export const unpublishPostRequest = () => {
    return {
        type: ActionTypes.UNPUBLISH_POST_REQUEST
    }
};

export const unpublishPostSuccess = (status, post) => {
    return {
        type: ActionTypes.UNPUBLISH_POST_SUCCESS,
        status,
        post
    }
};

export const unpublishPost = (dispatch, id) => {
    return new Promise(function (resolve, reject) {
        dispatch(unpublishPostRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.POST}/${id}/unpublish`;
        config["method"] = "post";
        config["data"] = JSON.stringify({});

        axios(config)
            .then(function (response) {
                dispatch(unpublishPostSuccess(response.data.status, response.data.post))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}



export const updatePostRequest = () => {
    return {
        type: ActionTypes.UPDATE_POST_REQUEST
    }
};

export const updatePostSuccess = (post) => {
    return {
        type: ActionTypes.UPDATE_POST_SUCCESS,
        post
    }
};

export const updatePost = (dispatch, id, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(updatePostRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.POST}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(updatePostSuccess(response.data.post))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}




export const fetchHousesRequest = () => {
    return {
        type: ActionTypes.GET_HOUSES_REQUEST
    }
};

export const fetchHousesSuccess = (houses) => {
    return {
        type: ActionTypes.GET_HOUSES_SUCCESS,
        houses

    }
};

export const fetchHouses = (dispatch) => {

    dispatch(fetchHousesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.HOUSES}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchHousesSuccess(response.data.houses))
        })
        .catch(error => {
            throw(error);
        });
}




/*

admin
 */

export const createUserRequest = () => {
    return {
        type: ActionTypes.CREATE_USER_REQUEST
    }
};

export const createUserSuccess = (user, status) => {
    return {
        type: ActionTypes.CREATE_USER_SUCCESS,
        user,
        status
    }
};

export const createUser = (dispatch, info) => {

    return new Promise(function (resolve, reject) {


        // We dispatch requestLogin to kickoff the call to the API
        dispatch(createUserRequest())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.ADMIN}/user`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(createUserSuccess(response.data.user, response.data.status))
                resolve(response);
            })
            .catch(
                err => {
                    reject(err);
                }
            )
    });
}



export const createHouseRequest = () => {
    return {
        type: ActionTypes.CREATE_HOUSE_REQUEST
    }
};

export const createHouseSuccess = (house, status) => {
    return {
        type: ActionTypes.CREATE_HOUSE_SUCCESS,
        house,
        status
    }
};

export const createHouse = (dispatch, info) => {

    return new Promise(function (resolve, reject) {


        // We dispatch requestLogin to kickoff the call to the API
        dispatch(createHouseRequest())
        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.ADMIN}/house`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(createHouseSuccess(response.data.house, response.data.status))
                resolve(response);
            })
            .catch(
                err => {
                    reject(err);
                }
            )
    });
}



/*
    Signup code
 */

export const fetchSignupCodesRequest = () => {
    return {
        type: ActionTypes.GET_SIGNUP_CODES_REQUEST
    }
};

export const fetchSignupCodesSuccess = (codes, meta) => {
    return {
        type: ActionTypes.GET_SIGNUP_CODES_SUCCESS,
        codes,
        meta
    }
};

export const fetchSignupCodes = (dispatch,  house, appt, page, pagesize, order, asc) => {

    dispatch(fetchSignupCodesRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.SIGNUP_CODES}/?house=${house}&appt=${appt}&page=${page}&pagesize=${pagesize}&order=${order}&asc=${asc}`;
    config["method"] = "get";

    return axios(config)
        .then(response => {
            dispatch(fetchSignupCodesSuccess(response.data.codes, response.data.meta))
        })
        .catch(error => {
            throw(error);
        });
}



export const toggleMainMenu = () => {
    return {
        type: ActionTypes.TOGGLE_MAIN_MENU
    }
};


export const createSignupCodeRequest = () => {
    return {
        type: ActionTypes.CREATE_SIGNUP_CODE_REQUEST
    }
};

export const createSignupCodeSuccess = (status, code) => {
    return {
        type: ActionTypes.CREATE_SIGNUP_CODE_SUCCESS,
        status,
        code
    }
};

export const createSignupCode = (dispatch, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(createSignupCodeRequest())

        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = Endpoints.SIGNUP_CODES;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(function (response) {
                dispatch(createSignupCodeSuccess(response.data.status, response.data.code))
                resolve(response);
            })
            .catch(function (err) {
                console.log("Error: ", err)
                reject(err);
            });
    });
}

export const removeSignupCodeRequest = () => {
    return {
        type: ActionTypes.DELETE_SIGNUP_CODE_REQUEST
    }
};

export const removeSignupCodeSuccess = (code) => {
    return {
        type: ActionTypes.DELETE_SIGNUP_CODE_SUCCESS,
        code
    }
};

export const removeSignupCode = (dispatch, id) => {

    return new Promise(function (resolve, reject) {
        dispatch(removeSignupCodeRequest());
        let header = {}
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.SIGNUP_CODE}/${id}`;
        config["method"] = "delete";

        axios(config)
            .then(response => {
                dispatch(removeSignupCodeSuccess(response.data.code))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

/*
SMS
 */

const fetchSmsItemRequest = () => {
    return {
        type: ActionTypes.FETCH_SMS_ITEM_REQUEST
    }
};

const fetchSmsItemSuccess = (status, item) => {
    return {
        type: ActionTypes.FETCH_SMS_ITEM_SUCCESS,
        status,
        item
    }
};

export const fetchSmsItem = (dispatch,  itemId) => {

    dispatch(fetchSmsItemRequest())
    let header = {};
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.SMS_ITEM}/${itemId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSmsItemSuccess(response.data.status, response.data.item))
        })
        .catch(error => {

            throw(error);

        });
}

const sendSmsRequest = () => {
    return {
        type: ActionTypes.SEND_SMS_REQUEST
    }
};

const sendSmsSuccess = (id, status, phone) => {
    return {
        type: ActionTypes.SEND_SMS_SUCCESS,
        id,
        status,
        phone
    }
};

export function sendSms(dispatch, id, info) {

    dispatch(sendSmsRequest())

    let header = {}

    header["Accept"] = "application/json"
    header["Content-Type"] = "application/json"

    let config = {}
    config["headers"] = header;
    config["url"] = `${Endpoints.SMS_ITEM}/${id}`;
    config["method"] = "post";
    config["data"] = JSON.stringify(info);

    return axios.instance(config)
        .then(response => {

            dispatch(sendSmsSuccess(id, info.status, info.phone))
        })
        .catch(error => {

            if (error.response === undefined) {
                dispatch(failure("Error", error.message));
            } else {
                dispatch(failure(error.response.data.error, error.response.data.message));
            }

        });
}
