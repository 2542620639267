/**
 * Created by gantushig on 4/16/17.
 */

import React, {useReducer} from "react";
import {Link} from "react-router-dom";
import {createHouse, createUser, signup} from "./actions/actions";
import {ActionTypes} from "./Constants";
import history from "./history";
import packageJson from "../package.json";
import {FaBuilding} from "react-icons/fa";
import BarLoader from "react-spinners/BarLoader";
import {css} from "@emotion/react";


const override = css`
  display: block;
  margin: 20px 0;
  border-color: #177892;
`;

const initialState = {
    field:{name: "",    number: "",    address: "",    org_id: null},
    loading: false,
    errorMessage: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_HOUSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_HOUSE_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.field};
            c[action.name] = action.value;
            return {
                ...state,
                field: c
            }
        }
        case 'SET_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage
            }
        }

        default:
            return state;
    }
};


const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}


const setError = (errorMessage) => {
    return {
        type: 'SET_ERROR',
        errorMessage
    }
}


const AdminHouse = () => {
    //const [email, setEmail] = useState("");

    const [state, dispatch] = useReducer(reducer, initialState);
    const {field, loading, errorMessage} = state;

    //setError(sauth.errorMEssage)
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('send');

        createHouse(dispatch, field).then(response => {

            }
        ).catch(error => {
            alert(error)
        });

    };


    return (
        <div>
            <nav className="flex items-center justify-between flex-wrap bg-white p-6">
                <div className="flex items-center flex-shrink-0  mr-6">
                    <Link to="/">
                    <span className="text-xl font-extrabold text-purple-500"><FaBuilding size={30} className="inline text-purple-500"/> MANAI BAIR</span>
                    </Link>
                </div>
                <div className="w-full block flex-grow flex items-center w-auto">
                    <div className="text-sm flex-grow">

                    </div>
                </div>
            </nav>

            <div className="group m-8 md:m-32" style={{marginBottom: "50px", marginTop: "100px"}}>

                <BarLoader color="red" loading={loading} css={override}/>
                <h1 className="mt-8  text-xl font-light">House</h1>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="group">

                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">number</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="number"
                                type="text"
                                value={field.number}
                                onChange={handleChangeField}
                                placeholder="Number оруулах"/>
                        </div>

                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">нэр</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="name"
                                type="text"
                                value={field.name}
                                onChange={handleChangeField}
                                placeholder="Нэр оруулах"/>
                        </div>
                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">Address</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="address"
                                type="text"
                                value={field.address}
                                onChange={handleChangeField}
                                placeholder="Address оруулах"/>
                        </div>
                        <div className="group mt-8">
                            <label className="text-sm text-gray-400" htmlFor="name">org id</label>
                            <input
                                className="form-input border-2 border-gray-200 px-2 py-2 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                name="org_id"
                                type="number"
                                value={field.org_id}
                                onChange={handleChangeField}
                                placeholder="Org Id оруулах"/>
                        </div>


                        <button className="w-full md:w-1/2 mt-8 py-3 px-3 bg-purple-600 font-semibold text-white" type="submit">
                            Send
                        </button>
                        {errorMessage &&
                        <div style={{margin: "5px"}}><span
                            style={{color: "red", fontSize: "smaller"}}>{errorMessage}</span></div>
                        }

                    </div>
                </form>
            </div>


            <footer>
                <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 mt-48">
                    <p>&copy;2021 Manai bair. Strong Neighbours,
                        Version {packageJson.version}</p>
                </nav>
            </footer>
        </div>
    )
}


export default AdminHouse;