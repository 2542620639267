/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useReducer} from "react";
import {createPaymentNote, createPost, fetchOrder} from "./actions/actions";
import {ActionTypes} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaAngleDown} from 'react-icons/fa';
import history from "./history";
import bank from "./images/payment-account.png";
import nano from "./images/nano-receive.png"
import classNames from "classnames";
import PostEditComponent from "./components/PostEditComponent";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    subject:"",
    post:{},
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.CREATE_POST_SUCCESS: {
            return {
                ...state,
                post: action.post,
                loading: false
            }
        }
        case 'CHANGE_NOTE': {
            return {
                ...state,
                subject: action.subject,
            }
        }
        default:
            return state;
    }
};



const changeNote = (subject) => {
    return {
        type: 'CHANGE_NOTE',
        subject
    }
}

const PostCreate = () => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { subject} = state;


    const handleChangeField = (e) => {
        dispatch(changeNote(e.target.value));
    };

    const handleSave = (e) => {
        if (subject === "") {
            toast.error("Мэдэгдэл хоосон байж болохгүй!");
            return;
        }
        let info = {}
        info.subject = subject;
        createPost(dispatch, info).then(response => {
                toast.success("Мэдэгдэл хадгаллаа!");
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = (e) => {
        e.preventDefault();
        history.goBack()
    }

    return (
        <div className="p-5">

            <h2 className="mt-1 text-lg font-bold">Мэдэгдэл үүсгэх</h2>
            <PostEditComponent  onChangeField={handleChangeField} onClickCancel={handleCancel} onClickSave={handleSave} subject={subject}/>
        </div>
    )
}


export default PostCreate;

