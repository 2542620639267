import React, {useReducer} from "react";
import {logoutUser, toggleMainMenu} from "./actions/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import packageJson from "../package.json";
import {Link} from "react-router-dom";
import classd from "classd";
import history from "./history"
import {FaBuilding} from "react-icons/fa";




const MainWrapper = WrappedComponent => {
    // const [state, localDispatch] = useReducer(reducer, initialState);
    // const {openMenu} = state;

    return (props) => {

        const {logout, toggleMenu, openMenu} = props

        const handleLogout = () => {
            toggleMenu();
            logout()
        }

        const handleProfileClick = () => {
            toggleMenu();
        }

        const handlePostsClick = (e) => {
            e.preventDefault();
            toggleMenu();
            history.push("/posts");
        }
        const handleSignupCodesClick = (e) => {
            e.preventDefault();
            toggleMenu();
            history.push("/signup-codes");
        }


        return (

            <div className="flex flex-col h-screen justify-between">
                <nav className="h-14 bg-indigo-500 flex items-center justify-between flex-wrap pl-6">
                    <div className="flex align-middle items-center flex-shrink-0  mr-6">
                        <Link className="font-medium text-white text-xl tracking-tight no-underline hover:no-underline"
                              to="/">
                            <span className="inline text-white text-xl font-extrabold"><FaBuilding className="inline" size={24} color="white"/> MANAIBAIR</span>
                        </Link>
                    </div>
                    <div className="block flex-grow flex items-center w-auto">
                        <div className="text-sm flex-grow">

                        </div>
                        <div className="flex justify-center p-2">

                            <div className="relative">
                                <button onClick={handleProfileClick}
                                        className="block h-10 w-10 rounded-full overflow-hidden focus:outline-none">
                                    <img className="h-full w-full object-cover"
                                         src="https://eu.ui-avatars.com/api/?name=Xyoni&size=1000" alt="avatar"/>
                                </button>

                                <div className={classd`absolute right-0 w-40 mt-2 py-2 bg-white border rounded shadow-xl ${{'invisible': !openMenu, 'visible':openMenu}}`}>
                                    <a href="#" onClick={handleLogout}
                                       className="transition-colors duration-200 block px-4 py-2 text-normal text-gray-900 rounded hover:bg-purple-500 hover:text-white">
                                        Logout
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div>
                </nav>

                    <div className="container mx-auto mb-auto">
                        <WrappedComponent
                            {...
                                props
                            }
                        />
                    </div>

                <footer className="h-16 flex bg-gray-900 px-5 py-8 text-white">
                    <p className=""> Copyright &copy; 2021 Manaibair.com. Strong Neighbours,
                        Version {packageJson.version}</p>

                </footer>
            </div>

        )
            ;
    }
}


const mapStateToProps = (state) => {
    const auth = state.auth;
    return {
        openMenu:auth.openMenu
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutUser())
        },
        toggleMenu: () => {
            dispatch(toggleMainMenu())
        },

    }
}


const composedWrapApp = compose(connect(mapStateToProps, mapDispatchToProps), MainWrapper);

export default composedWrapApp;
