/**
 * Created by gantushig on 4/16/17.
 */


import {combineReducers} from "redux";
import {ActionTypes, LOGGED_EMAIL, LOGGED_IN} from "../Constants";

const authInitialState = {
    openMenu:false,
    isFetching: false,
    isAuthenticated: !!localStorage.getItem(LOGGED_IN),
    errorMessage: ""
};

const authReducer = (state = authInitialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                errorMessage: ""
            };
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                errorMessage: ""
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message
            };
        case ActionTypes.LOGOUT_SUCCESS:
            localStorage.removeItem(LOGGED_IN);
            localStorage.removeItem(LOGGED_EMAIL);
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: ""
            }
        case ActionTypes.TOGGLE_MAIN_MENU:
            return {
                ...state,
                openMenu: !state.openMenu
            }

        default:
            return state
    }
}



const rootReducer = combineReducers({
    auth: authReducer
})

export default rootReducer