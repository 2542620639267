/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import ReactPaginate from "react-paginate";
import {
    fetchHouses,
    fetchPosts,
    fetchReadings,
    publishPost,
    removePost,
    removeReading,
    unpublishPost
} from "./actions/actions";
import {ActionTypes, Endpoints, Page} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaTimes, FaCloudDownloadAlt, FaCloudUploadAlt, FaPen} from 'react-icons/fa';
import dayjs from "dayjs";
import qs from "query-string";
import history from "./history";
import classd from "classd";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    openModal:false,
    idToRemove:null,
    search_form: {
        status: "",
    },
    posts: [],
    meta: {totalPages: 0},
    loading: false,
    refresh: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_POSTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_POSTS_SUCCESS: {
            return {
                ...state,
                posts: action.posts,
                meta: action.meta,
                loading: false
            }
        }
        case ActionTypes.PUBLISH_POST_REQUEST:
        case ActionTypes.UNPUBLISH_POST_REQUEST:
        case ActionTypes.DELETE_POST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.PUBLISH_POST_SUCCESS:
        case ActionTypes.UNPUBLISH_POST_SUCCESS: {
            let a = state.posts.map( p=> {
                if (p.id === action.post.id) {
                    return action.post;
                } else {
                    return p;
                }
            })
            return {
                ...state,
                posts: a,
                loading: false
            }
        }
        case ActionTypes.DELETE_POST_SUCCESS: {
            let a = state.posts.filter( p=> {
                if (p.id !== action.post.id) {
                    return p;
                }
            })
            return {
                ...state,
                posts: a,
                loading: false
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.search_form};
            c[action.name] = action.value
            return {
                ...state,
                search_form: c,
            }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                openModal: true,
                idToRemove:action.id
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                openModal: false,
                idToRemove:null
            }
        }
        default:
            return state;
    }
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const openModalDialog = (id) => {
    return {
        type: 'OPEN_MODAL',
        id
    }
}
const closeModalDialog = () => {
    return {
        type: 'CLOSE_MODAL',
    }
}

const PostsPage = ({
                          location: {search}
                      }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {posts, meta, loading, search_form,idToRemove, openModal} = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let status = parsed["s"];
    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (status === undefined) {
        status = null;
    }

    useEffect(() => {
        console.log('useEffect called');
        fetchPosts(dispatch, status, page, pagesize, orderField, isAsc)
    }, [status,  orderField, isAsc, page, pagesize]);


    //const history = useHistory();

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleRemovePost = (e, id) => {
        dispatch(openModalDialog(id));

    };
    const handleCloseModal = (e) => {
        dispatch(closeModalDialog());
    }
    const handleOkModal = (e) => {
        dispatch(closeModalDialog());
        removePost(dispatch, idToRemove).then(response => {
                toast.success("Амжилттай устгагдлаа!");
            }
        ).catch(error => {
            alert(error)
        });

    }
    const handleEditPost = (e, id) => {
        let path =  `/post/${id}`
        history.push(path);
    };
    const handlePublishPost = (e, id) => {
        publishPost(dispatch, id).then(response => {
                toast.success("Амжилттай нийтлэгдлээ!");
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleUnpublishPost = (e, id) => {
        unpublishPost(dispatch, id).then(response => {
                toast.success("Амжилттай unpublished!");
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["s"] = search_form.status;
        history.push("?" + qs.stringify(parsed))
    };

    const getItemStatus = (status) => {
        if (status === 'PUBLISHED') {
            return "НИЙТЭЛСЭН";
        } else if (status === 'DRAFT') {
            return "БЭЛТГЭЖ БАЙГАА";
        } else {
            return "";
        }

    }
    const items = posts.map(
        (item) => {
            return (
                <tr key={item.id}>
                    <td className="py-3 px-2 border ">
                        {item.subject}
                        <span className="block">{item.created && dayjs(item.created).format("MM/DD/YYYY")}</span>
                        <div className="block my-2">
                        <span className={classd` text-white py-2 px-3 ${{'bg-green-500': item.status === 'PUBLISHED'}} ${{'bg-yellow-500': item.status === 'DRAFT'}}`}>
                        {getItemStatus(item.status)}
                        </span>
                        </div>
                        <div className="block my-5">
                            <button className="bg-blue-500 p-2 text-white rounded-full"
                                    onClick={(e) => handleEditPost(e, item.id)}><FaPen /></button>
                            <button className="bg-green-500 ml-1 p-2 text-white  rounded-full"
                                    onClick={(e) => handlePublishPost(e, item.id)}><FaCloudUploadAlt/></button>
                            <button className="bg-yellow-500 ml-1 p-2 text-white rounded-full"
                                    onClick={(e) => handleUnpublishPost(e, item.id)}><FaCloudDownloadAlt/></button>
                            <button className="bg-red-500 ml-1 p-2 text-white rounded-full"
                                    onClick={(e) => handleRemovePost(e, item.id)}><FaTimes/></button>
                        </div>
                    </td>
                </tr>
            )
        });
    return (
        <div className="p-5 w-full">

            <Link className="block w-1/3 text-white text-center text-lg font-medium bg-green-400 mb-5 px-4 py-2" to={"/post/create"}>Мэдэгдэл Үүсгэх</Link>

            <table className="border-collapse border table-fixed w-full">
                <thead>
                <tr className="bg-gray-200">
                    <th className="w-2/3 py-3 px-2 border">Мэдэгдэл</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>

            <div
                className={classd`h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ${{'hidden': !openModal}}`}
            >
                <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
                    <div className="border-b px-4 py-2 flex justify-between items-center">
                        <h3 className="font-semibold text-lg">Мэдэгдэл устгах</h3>
                        <button className="text-black close-modal" onClick={handleCloseModal}><FaTimes/></button>
                    </div>
                    <div className="p-3 text-sm">
                        Та Мэдэгдэл устгахдаа итгэлтэй байна уу?
                    </div>
                    <div className="flex justify-end items-center w-100 border-t p-3">
                        <button onClick={handleCloseModal}
                                className="bg-gray-600 hover:bg-gray-700 px-3 py-1 rounded text-white mr-1 close-modal">Болих
                        </button>
                        <button onClick={handleOkModal}
                                className="bg-green-600 hover:bg-green-700 px-3 py-1 rounded text-white">Тийм
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}


const SearchPanel = ({
                         status,
                         onChangeField,
                         onClickSearch
                     }) => {

    return (
        <div className="my-3 mx-0">
                <div className="flex flex-col mb-4">
                    <label className="uppercase font-bold text-lg text-grey-darkest">Статус</label>
                    <select name="status" value={status} onChange={onChangeField} className="border py-2 px-3 text-grey-darkest">
                        <option  value=""></option>
                        <option  value="PUBLISHED">Нийтэлсэн</option>
                    </select>
                </div>

                <button onClick={onClickSearch} className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg mx-auto p-4 rounded">
                    Хайх
                </button>
        </div>
    )
}

export default PostsPage;

