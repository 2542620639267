/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";
import {FaRocket} from "react-icons/fa";


const SignupThanks = () => {


    return (
        <div>
            <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="text-xl font-extrabold text-purple-500">QRCODE</span> <FaRocket size={30} className="inline text-purple-500"/>
                </div>
            </nav>
            <div className="flex flex-wrap justify-center content-center">
                <div className="group m-32 w-64">
                    <h1 className="inline-block font-normal text-center text-5xl my-20">Бүртгүүлсэнд баярлалаа!</h1>
                    <p className=" inline-block font-normal text-center  text-base">
                        Та имэйлээ шалгана уу. Бид танд баталгаажуулах имэйл илгээсэн болно.
                    </p>
                    <p className="inline-block font-normal text-center  text-sm mt-10 ">
                        Хэрэв имэйл хүлээж аваагүй бол Спам хавтсаа шалгана уу!
                    </p>

                </div>
            </div>
        </div>
    )
}


export default SignupThanks;