import {Router, Route, Switch} from "react-router-dom";
import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {Provider} from "react-redux";
import rootReducer from "./reducers/reducers";

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import composedWrapApp from './App';
import MainView from './MainView';
import LoginEmail from './LoginEmail';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import * as serviceWorker from './serviceWorker';
import requireAuth from "./AccountManager";

import history from "./history"
import SignupThanks from "./SignupThanks";
import StorePassword from "./StorePassword";
import ResetPassword from "./ResetPassword";
import ReadingsPage from "./ReadingsPage";
import PostsPage from "./PostsPage";
import PostCreate from "./PostCreate";
import PostEdit from "./PostEdit";
import Signup from "./Signup";
import ConfirmEmail from "./ConfirmEmail";
import AdminHouse from "./AdminHouse";
import AdminUser from "./AdminUser";
import SignupCodesPage from "./SignupCodesPage";
import SignupCodeCreate from "./SignupCodeCreate";
import ValuesFreePage from "./ValuesFreePage";
import ChangePassword from "./ChangePassword";


const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
);


ReactDOM.render(
    <Provider store={store}>
        <div><ToastContainer />
        <Router history={history}>
            <Switch>
                <Route path="/" exact={true} component={requireAuth(composedWrapApp(MainView))} />}/>
                <Route path="/readings"   component={requireAuth(composedWrapApp(ReadingsPage))} />}/>
                <Route path="/values-free"   component={requireAuth(composedWrapApp(ValuesFreePage))} />}/>
                <Route path="/signup-codes"   component={requireAuth(composedWrapApp(SignupCodesPage))} />}/>
                <Route path="/signup-code-create"   component={requireAuth(composedWrapApp(SignupCodeCreate))} />}/>
                <Route path="/admin-house"  component={requireAuth(composedWrapApp(AdminHouse))}/>
                <Route path="/admin-user"  component={requireAuth(composedWrapApp(AdminUser))}/>
                <Route path="/post/create"   component={requireAuth(composedWrapApp(PostCreate))} />}/>
                <Route path="/post/:id"   component={requireAuth(composedWrapApp(PostEdit))} />}/>
                <Route path="/posts"   component={requireAuth(composedWrapApp(PostsPage))} />}/>
                <Route path="/confirm/:code"  component={ConfirmEmail}/>
                <Route path="/change-password/:code"  component={ChangePassword}/>
                <Route path="/signup"  component={Signup}/>
                <Route path="/signup-thanks"  component={SignupThanks}/>
                <Route path="/login"  component={LoginEmail}/>
                <Route path="/change-password/:code"  component={StorePassword}/>
                <Route path="/reset-password"  component={ResetPassword}/>
            </Switch>
        </Router>
        </div>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
