/**
 * Created by gantushig on 1/30/16.
 */

import keyMirror from 'keymirror';


//export const BASE_URL= '/';

//dev
//export const BASE_URL= 'http://localhost:3028/';

//prod
export const BASE_URL= 'https://api.manaibair.com/';


//export const BASE_URL= 'https://api2.gs1mongolia.org/';
//export const BASE_URL= 'http://172.104.86.125:3000/';
//export const BASE_URL= 'http://gs1api.inextmon.com/';
//export const BASE_URL= 'http://192.168.1.4:8090/';
//export const BASE_URL= 'http://192.168.1.121:8090/';
//export const BASE_URL= 'http://192.168.1.6:8090/';
//export const BASE_URL= 'http://192.168.0.101:8090/';
//export const BASE_URL= 'http://192.168.0.102:8090/';
//export const BASE_URL= 'http://192.168.8.2:3000/';
//export const BASE_URL= 'http://192.168.8.4:8090/';


export const TOKEN = "xyoni_token"
export const LOGGED_IN = "logged_in";
export const LOGGED_EMAIL = "logged_email";

export const ActionTypes =  keyMirror({

    FAILURE:null,

    LOGIN:null,
    LOGIN_REQUEST:null,
    LOGIN_SUCCESS:null,
    LOGIN_FAILURE:null,
    VERIFY_FAILURE:null,
    LOGOUT:null,

    SIGNUP_REQUEST:null,
    SIGNUP_SUCCESS:null,
    SIGNUP_FAILURE:null,

    LOGOUT_REQUEST:null,
    LOGOUT_SUCCESS:null,
    LOGOUT_FAILURE:null,

    UPLOAD_BARCODE_REQUEST:null,
    UPLOAD_BARCODE_SUCCESS:null,
    UPLOAD_BARCODE_FAILURE:null,

    DELETE_BARCODE_REQUEST:null,
    DELETE_BARCODE_SUCCESS:null,
    DELETE_BARCODE_FAILURE:null,

    LOAD_DEVICES_REQUEST:null,
    LOAD_DEVICES_SUCCESS:null,
    LOAD_DEVICES_FAILURE:null,

    LOAD_CODES_REQUEST:null,
    LOAD_CODES_SUCCESS:null,
    LOAD_CODES_FAILURE:null,

    GET_CODE_REQUEST:null,
    GET_CODE_SUCCESS:null,
    GET_CODE_FAILURE:null,

    GET_ORDER_REQUEST:null,
    GET_ORDER_SUCCESS:null,
    GET_ORDER_FAILURE:null,

    CONFIRM_EMAIL_REQUEST:null,
    CONFIRM_EMAIL_SUCCESS:null,
    CONFIRM_EMAIL_FAILURE:null,

    CHECK_USER_TRIAL_REQUEST:null,
    CHECK_USER_TRIAL_SUCCESS:null,
    CHECK_USER_TRIAL_FAILURE:null,

    START_USER_TRIAL_REQUEST:null,
    START_USER_TRIAL_SUCCESS:null,
    START_USER_TRIAL_FAILURE:null,

    GET_ACTIVE_CODE_COUNT_REQUEST:null,
    GET_ACTIVE_CODE_COUNT_SUCCESS:null,
    GET_ACTIVE_CODE_COUNT_FAILURE:null,

    GET_CODE_STYLING_REQUEST:null,
    GET_CODE_STYLING_SUCCESS:null,
    GET_CODE_STYLING_FAILURE:null,

    GET_PRODUCT_IMAGE_REQUEST:null,
    GET_PRODUCT_IMAGE_SUCCESS:null,
    GET_PRODUCT_IMAGE_FAILURE:null,

    GET_ORDERS_REQUEST:null,
    GET_ORDERS_SUCCESS:null,
    GET_ORDERS_FAILURE:null,

    RESET_PASSWORD_REQUEST:null,
    RESET_PASSWORD_SUCCESS:null,
    RESET_PASSWORD_FAILURE:null,

    STORE_PASSWORD_REQUEST:null,
    STORE_PASSWORD_SUCCESS:null,
    STORE_PASSWORD_FAILURE:null,

    CREATE_ORDER_REQUEST:null,
    CREATE_ORDER_SUCCESS:null,
    CREATE_ORDER_FAILURE:null,

    DELETE_ORDER_REQUEST:null,
    DELETE_ORDER_SUCCESS:null,
    DELETE_ORDER_FAILURE:null,

    UPLOAD_PRODUCT_IMAGE_REQUEST:null,
    UPLOAD_PRODUCT_IMAGE_SUCCESS:null,
    UPLOAD_PRODUCT_IMAGE_FAILURE:null,

    UPLOAD_LOGO_REQUEST:null,
    UPLOAD_LOGO_SUCCESS:null,
    UPLOAD_LOGO_FAILURE:null,

    ENTER_READING_FROM_QRCODE_REQUEST:null,
    ENTER_READING_FROM_QRCODE_SUCCESS:null,
    ENTER_READING_FROM_QRCODE_FAILURE:null,

    CREATE_CODE_REQUEST:null,
    CREATE_CODE_SUCCESS:null,
    CREATE_CODE_FAILURE:null,

    DELETE_CODE_REQUEST:null,
    DELETE_CODE_SUCCESS:null,
    DELETE_CODE_FAILURE:null,

    ACTIVATE_CODE_REQUEST:null,
    ACTIVATE_CODE_SUCCESS:null,
    ACTIVATE_CODE_FAILURE:null,

    DEACTIVATE_CODE_REQUEST:null,
    DEACTIVATE_CODE_SUCCESS:null,
    DEACTIVATE_CODE_FAILURE:null,

    UPDATE_CODE_REQUEST:null,
    UPDATE_CODE_SUCCESS:null,
    UPDATE_CODE_FAILURE:null,

    UPDATE_CODE_STYLING_REQUEST:null,
    UPDATE_CODE_STYLING_SUCCESS:null,
    UPDATE_CODE_STYLING_FAILURE:null,

    DELETE_PRODUCT_IMAGE_REQUEST:null,
    DELETE_PRODUCT_IMAGE_SUCCESS:null,
    DELETE_PRODUCT_IMAGE_FAILURE:null,

    DOWNLOAD_BARCODE_REQUEST:null,
    DOWNLOAD_BARCODE_SUCCESS:null,
    DOWNLOAD_BARCODE_FAILURE:null,

    CREATE_PAYMENT_NOTE_REQUEST:null,
    CREATE_PAYMENT_NOTE_SUCCESS:null,
    CREATE_PAYMENT_NOTE_FAILURE:null,

    TOGGLE_MAIN_MENU:null,

    GET_HOUSE_REQUEST:null,
    GET_HOUSE_SUCCESS:null,
    GET_HOUSE_FAILURE:null,

    GET_HOUSES_REQUEST:null,
    GET_HOUSES_SUCCESS:null,
    GET_HOUSES_FAILURE:null,

    GET_SIGNUP_CODES_REQUEST:null,
    GET_SIGNUP_CODES_SUCCESS:null,
    GET_SIGNUP_CODES_FAILURE:null,

    CREATE_SIGNUP_CODE_REQUEST:null,
    CREATE_SIGNUP_CODE_SUCCESS:null,
    CREATE_SIGNUP_CODE_FAILURE:null,

    DELETE_SIGNUP_CODE_REQUEST:null,
    DELETE_SIGNUP_CODE_SUCCESS:null,
    DELETE_SIGNUP_CODE_FAILURE:null,

    GET_READINGS_REQUEST:null,
    GET_READINGS_SUCCESS:null,
    GET_READINGS_FAILURE:null,

    GET_VALUES_FREE_REQUEST:null,
    GET_VALUES_FREE_SUCCESS:null,
    GET_VALUES_FREE_FAILURE:null,

    GET_USERINFO_REQUEST:null,
    GET_USERINFO_SUCCESS:null,
    GET_USERINFO_FAILURE:null,

    DELETE_DEVICE_REQUEST:null,
    DELETE_DEVICE_SUCCESS:null,
    DELETE_DEVICE_FAILURE:null,

    DELETE_READING_REQUEST:null,
    DELETE_READING_SUCCESS:null,
    DELETE_READING_FAILURE:null,

    CREATE_POST_REQUEST:null,
    CREATE_POST_SUCCESS:null,
    CREATE_POST_FAILURE:null,

    SAVE_POST_REQUEST:null,
    SAVE_POST_SUCCESS:null,
    SAVE_POST_FAILURE:null,

    GET_POSTS_REQUEST:null,
    GET_POSTS_SUCCESS:null,
    GET_POSTS_FAILURE:null,

    GET_POST_REQUEST:null,
    GET_POST_SUCCESS:null,
    GET_POST_FAILURE:null,

    PUBLISH_POST_REQUEST:null,
    PUBLISH_POST_SUCCESS:null,
    PUBLISH_POST_FAILURE:null,

    UNPUBLISH_POST_REQUEST:null,
    UNPUBLISH_POST_SUCCESS:null,
    UNPUBLISH_POST_FAILURE:null,

    UPDATE_POST_REQUEST:null,
    UPDATE_POST_SUCCESS:null,
    UPDATE_POST_FAILURE:null,

    DELETE_POST_REQUEST:null,
    DELETE_POST_SUCCESS:null,
    DELETE_POST_FAILURE:null,

    CREATE_USER_REQUEST:null,
    CREATE_USER_SUCCESS:null,
    CREATE_USER_FAILURE:null,

    CREATE_HOUSE_REQUEST:null,
    CREATE_HOUSE_SUCCESS:null,
    CREATE_HOUSE_FAILURE:null,

    LOAD_SMS_TEMPLATE_REQUEST:null,
    LOAD_SMS_TEMPLATE_SUCCESS:null,
    LOAD_SMS_TEMPLATE_FAILURE:null,

    FETCH_SMS_ITEM_REQUEST:null,
    FETCH_SMS_ITEM_SUCCESS:null,
    FETCH_SMS_ITEM_FAILURE:null,

    SEND_SMS_REQUEST:null,
    SEND_SMS_SUCCESS:null,
    SEND_SMS_FAILURE:null,

});


export const Page  =  {
    PAGE:"p",
    PAGESIZE:"s",
    ASC:"asc",
    ORDER:"ord"
}

export const Endpoints  =  {
    ENTER_FROM_QRCODE:'/api/v1.0/reading-qrcode',
    HOUSE_BY_KEY: '/api/v1.0/house/key',
    HOUSE: '/api/v1.0/house',
    HOUSES: '/api/v1.0/houses',
    SIGNUP_URL:BASE_URL + 'api/v1.0/signup',
    LOGIN_URL:BASE_URL + 'api/v1.0/login',
    LOGOUTV1_URL:BASE_URL + '/api/v1.0/logout',

    CODES:'/api/v1.0/qrcodes',
    CODE: BASE_URL + 'api/v1.0/code',
    QRCODE: '/api/v1.0/qrcode',
    CODE_STYLING: '/api/v1.0/qrcode-styling',
    CODE_LOGO: '/api/v1.0/qrcode-logo',
    PDF:  '/api/v1.0/pdf',

    ORDERS:'/api/v1.0/orders',
    ORDER: '/api/v1.0/order',
    PAYMENT_NOTE:'/api/v1.0/payment-note',

    USER_TRIAL:BASE_URL + '/api/v1.0/user-trial',
    CONFIRM_EMAIL:BASE_URL + '/api/v1.0/auth/user/verify',
    RESET_PASSWORD:BASE_URL + '/api/v1.0/reset-password',
    CHANGE_PASSWORD:BASE_URL + '/api/v1.0/change-password',

    READING:'/api/v1.0/reading',
    READINGS:'/api/v1.0/readings',
    VALUES_FREE:BASE_URL + 'api/v1.0/values-free',
    POST:BASE_URL + 'api/v1.0/post',
    POSTS:BASE_URL + 'api/v1.0/posts',
    ADMIN:'/api/v1.0/gst',

    SIGNUP_CODE:BASE_URL + '/api/v1.0/signup-code',
    SIGNUP_CODES:BASE_URL + '/api/v1.0/signup-codes',

    DEVICES:'/api/v1.0/devices',

    SMS_TEMPLATE:'/api/v1.0/sms-template',
    SMS_ITEMS:'/api/v1.0/sms-items',
    SMS_ITEM:'/api/v1.0/sms-item',

}

