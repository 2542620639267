/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import {confirmEmail} from "./actions/actions";
import {css} from "@emotion/react";
import packageJson from "../package.json";
import {ActionTypes} from "./Constants";
import {Link} from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import {FaRocket} from "react-icons/fa";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    status: "",
    confirmed: false,
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.CONFIRM_EMAIL_REQUEST:
            return {
                ...state,
                confirmed: false,
                loading: true
            }
        case ActionTypes.CONFIRM_EMAIL_SUCCESS: {
            let c = false;
            if (action.status === "ok") {
                c = true;
            } else if (action.status === "102") {

            } else {

            }
            return {
                ...state,
                confirmed: c,
                loading: false
            }
        }
        default:
            return state;
    }
};

const ConfirmEmail = ({match: {params: {code}}}) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {loading, confirmed, status} = state;

    useEffect(() => {
        console.log('useEffect called');
        confirmEmail(dispatch, code);
    }, []);

    return (
        <div>
            <BarLoader color="#ffffff" loading={loading} css={override}/>
            <div>
                <nav className="flex items-center justify-between flex-wrap bg-white p-6">
                    <div className="flex items-center flex-shrink-0  mr-6">
                        <span className="text-xl font-extrabold text-purple-500">QRCODE</span> <FaRocket size={30} className="inline text-purple-500"/>
                    </div>
                    <div className="w-full block flex-grow flex items-center w-auto">
                        <div className="text-sm flex-grow">

                        </div>
                    </div>
                </nav>
                {confirmed === true &&
                <div className="flex flex-wrap justify-center content-center">
                    <div className="group m-32 w-64">
                        <h1 className="inline-block font-normal text-center text-5xl my-20">Амжилттай!</h1>
                        <p className=" inline-block font-normal text-center  text-base">
                            Хэрэглэгч амжилттай баталгаажлаа!
                        </p>
                        <p className="inline-block font-normal text-center  text-sm mt-10 ">
                            <Link to={"/login"}> Нэвтрэх </Link>
                        </p>
                    </div>
                </div>
                }
                {confirmed === false &&
                <div className="flex flex-wrap justify-center content-center">
                    <div className="group m-32 w-64">
                        <h1 className="inline-block font-normal text-center text-5xl my-20">Алдаа!</h1>
                        <p className=" inline-block font-normal text-center  text-base">
                            Хэрэглэгчийг баталгаажуулaхад алдаа гарлаа! Имэйлээ дахин шалгана уу!<br/>
                        </p>
                        <p className="inline-block font-normal text-center  text-sm mt-10 ">
                            <Link to={"/login"}> Нэвтрэх </Link>
                        </p>
                    </div>
                </div>
                }
                <footer>
                    <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-6 mt-48">
                        <p>&copy; 2021 Xyoni. Proximity Solutions for All
                            Version {packageJson.version}</p>
                    </nav>
                </footer>
            </div>

        </div>
    )
}


export default ConfirmEmail;