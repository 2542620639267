/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useHistory} from "react-router-dom";
import React, {useEffect, useReducer} from "react";
import ReactPaginate from "react-paginate";
import {
    createCode,
    fetchHouses,
    fetchReadings,
    fetchSignupCodes,
    removeReading,
    removeSignupCode
} from "./actions/actions";
import {ActionTypes, Page} from './Constants';
import {toast} from "react-toastify";
import {css} from "@emotion/react";
import {FaTimes} from 'react-icons/fa';
import dayjs from "dayjs";
import qs from "query-string";
import history from "./history";
import classd from "classd";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: #177892;
`;

const initialState = {
    openModal:false,
    idToRemove:null,
    search_form: {
        house: "",
        appt: ""},
    codes: [],
    houses:[],
    meta: {totalPages: 0},
    loading: false,
    refresh: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_SIGNUP_CODES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_SIGNUP_CODES_SUCCESS: {
            return {
                ...state,
                codes: action.codes,
                meta: action.meta,
                loading: false
            }
        }
        case ActionTypes.DELETE_SIGNUP_CODE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.DELETE_SIGNUP_CODE_SUCCESS: {
            let a = state.codes.filter( p=> {
                if (p.id !== action.code.id) {
                    return p;
                }
            })
            return {
                ...state,
                codes: a,
                loading: false
            }
        }
        case ActionTypes.GET_HOUSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_HOUSES_SUCCESS: {
            return {
                ...state,
                houses: action.houses,
                loading: false
            }
        }
        case 'CHANGE_FIELD': {
            let c = {...state.search_form};
            c[action.name] = action.value
            return {
                ...state,
                search_form: c,
            }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                openModal: true,
                idToRemove:action.id
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                openModal: false,
                idToRemove:null
            }
        }

        default:
            return state;
    }
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const openModalDialog = (id) => {
    return {
        type: 'OPEN_MODAL',
        id
    }
}
const closeModalDialog = () => {
    return {
        type: 'CLOSE_MODAL',
    }
}

const SignupCodesPage = ({
                          location: {search}
                      }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const {codes, meta, loading, houses, search_form, idToRemove, openModal} = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let house = parsed["h"];
    let appt = parsed["a"];
    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (house === undefined) {
        house = null;
    }
    if (appt === undefined) {
        appt = null;
    }

    useEffect(() => {
        console.log('useEffect called');
        fetchSignupCodes(dispatch, house, appt, page, pagesize, orderField, isAsc)
    }, [house, appt, orderField, isAsc, page, pagesize]);

    useEffect(() => {
        fetchHouses(dispatch)
    }, []);

    //const history = useHistory();

    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };
    const handleRemoveCode = (e, id) => {
        dispatch(openModalDialog(id));
    };
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["h"] = search_form.house;
        parsed["a"] = search_form.appt;
        history.push("?" + qs.stringify(parsed))
    };


    const handleCloseModal = (e) => {
        dispatch(closeModalDialog());
    }
    const handleOkModal = (e) => {
        dispatch(closeModalDialog());
        removeSignupCode(dispatch, idToRemove).then(response => {
                //fetchReadings(dispatch);
                toast.success("Амжилттай устгагдлаа!");
            }
        ).catch(error => {
            alert(error)
        });

    }

    const items = codes.map(
        (item) => {
            return (
                <tr key={item.id}>
                    <td className="px-8 py-4 border ">
                        {item.house_number} ( {item.house_name} ) : {item.appt_number}
                    </td>
                    <td className="px-8 py-4 border ">
                        {item.code}
                    </td>
                    <td className="px-2 border">
                            <button className="bg-blue-500 p-1 text-white rounded-full"
                                    onClick={(e) => handleRemoveCode(e, item.id)}><FaTimes size={16}/></button>
                    </td>
                </tr>
            )
        });
    return (
        <div className="w-full p-5">

            <Link className="block bg-yellow-500 w-full sm:w-1/2 text-center py-2 px-5 text-white text-lg my-3 font-medium" to="/signup-code-create">Код Үүсгэх</Link>

            <h2 className="mt-1 text-gl font-medium">Бүртгэлийн Кодууд</h2>

            <SearchPanel houses={houses}
                onChangeField={handleChangeField}
                house={search_form.house}
                appt={search_form.appt}
                onClickSearch={handleSearch}

            />
            <table className="shadow-lg bg-white border-collapse border table-fixed w-full">
                <thead>
                <tr className="bg-gray-200">
                    <th className="w-1/3 text-left px-8 py-4 border">Байр/Тоот</th>
                    <th className="w-1/3 text-left px-8 py-4 border">Код</th>
                    <th className="w-16  text-left px-8 py-4 border"></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>


            <div
                className={classd`h-screen w-full fixed left-0 top-0 flex justify-center items-center bg-black bg-opacity-50 ${{'hidden': !openModal}}`}
            >
                <div className="bg-white rounded shadow-lg w-10/12 md:w-1/3">
                    <div className="border-b px-4 py-2 flex justify-between items-center">
                        <h3 className="font-semibold text-lg">Код устгах</h3>
                        <button className="text-black close-modal" onClick={handleCloseModal}><FaTimes/></button>
                    </div>
                    <div className="p-3 text-sm">
                        Та код устгахдаа итгэлтэй байна уу?
                    </div>
                    <div className="flex justify-end items-center w-100 border-t p-3">
                        <button onClick={handleCloseModal}
                                className="bg-gray-600 hover:bg-gray-700 px-3 py-1 rounded text-white mr-1 close-modal">Болих
                        </button>
                        <button onClick={handleOkModal}
                                className="bg-green-600 hover:bg-green-700 px-3 py-1 rounded text-white">Тийм
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )

}


const SearchPanel = ({
                         houses,
                         house, appt,
                         onChangeField,
                         onClickSearch
                     }) => {

    return (
        <div className="my-3 mx-0">
                <div className="flex flex-col mb-4">
                    <label className="uppercase font-bold text-lg text-grey-darkest">Байр</label>
                    <select name="house" value={house} onChange={onChangeField} className="border py-2 px-3 text-grey-darkest">
                        <option key={1} value=""></option>
                        {houses.map(house => {
                                return (
                                    <option key={house.id} value={house.id}>{house.number} - {house.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="flex flex-col mb-4">
                    <label className="mb-2 uppercase font-bold text-lg text-grey-darkest">Тоот</label>
                    <input name="appt" type="text" value={appt}  className="border py-2 px-3 text-grey-darkest"
                           onChange={onChangeField} placeholder="Тоотын дугаар оруулах"/>
                </div>


                <button onClick={onClickSearch} className="block bg-blue-500 hover:bg-blue-800 text-white uppercase text-lg mx-auto px-4 py-2 rounded">
                    Хайх
                </button>
        </div>
    )
}

export default SignupCodesPage;

